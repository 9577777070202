@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
    display: inline-block;
    color: #44455c;
}

input:focus,
select:focus {
    box-shadow: none !important;
    border: 0;
}

input,
select {
    font-size: 15px;
    color: #929292;
}

.btn.pageBtn {
    display: inline-block;
    background: #2e499c;
    color: #fff;
    font-size: 17px;
    padding: 8px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    transition: .4s ease-in-out;
}

.btn.pageBtn:hover {
    background-color: #44455c;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #44455c;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sectionPD {
    padding: 40px 0;
}

.sectionTitle h2 {
    font-size: 28px;
    margin-bottom: 24px;
}

.sectionTitle h2 span {
    color: #2e499c;
    font-weight: normal;
}

.greyBg {
    background-color: #f6f6f6;
}
/* Header Start */
.navInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.brand a {
    width: 90%;
}

.navInfo .menus {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.navInfo .menus ul {
    display: flex;
    align-items: center;
}

.navInfo .menus ul li a {
    padding: 10px 12px;
    color: #929292;
    transition: .4s ease-in-out;
    border-bottom: 2px solid #fff;
}

.btn.registerBtn {
    background-color: #2e499c;
    color: #fff;
    padding: 3px 15px;
}

.navInfo .menus ul li.active a {
    color: #252525;
    border-bottom: 2px solid #2e499c;
}

.navInfo .menus ul li a:hover {
    color: #2e499c;

}

button#dropdown-basic {
    background-color: transparent;
    border: 0;
    padding: 0;
}

button#dropdown-basic svg {
    color: #2e499c;
    font-size: 22px;
}

.dropdownMenu .dropdown-item {
    font-size: 15px;
}

/* Header End */

/* Footer Start */
.footerSec {
    background-color: #f2f6fd;
}

.footerInfo h5 {
    margin-bottom: 15px;
}

.footerInfo .links li a {
    font-size: 15px;
    padding: 3px 0;
    color: #696969;
    transition: .4s ease-in-out;
}

.footerInfo .links li a:hover {
    color: #2e499c;
}

.footerInfo .address li {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 15px;
}

.footerInfo .address .icon svg {
    font-size: 20px;
    color: #2e499c;
}

.footerInfo .address .content {
    color: #696969;

}

.footerInfo .address .content p,
.footerInfo .address .content a {
    font-size: 14px;
}

.copyRight {
    background: #f2f6fd;
    border-top: 1px solid #ccd2de;
}

.copyRightInfo p {
    padding: 12px 0;
    margin: 0;
    font-size: 14px;
    text-align: center;
}

.copyRightInfo p a {
    color: #2e499c;
}

.footerInfo p {
    font-size: 15px;
    color: #696969;
}

.readMore {
    color: #2e499c;
}

    .usernavdash li a {
        border-bottom: none;
        padding: 10px 15px;
        color: #444;
        display: block;
    }
    .usernavdash li svg {
        margin-right: 2px;
        font-size: 21px;
        width: 25px;
        color: #000;
    }
/* Footer End */

.usernavdash li a.active {
    background-color: #2e499c !important;
    color: white !important;
}
 .image img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    float: left;
}
.nav-pills .message-grid.active {
    background-color: #df4661 !important;
}
.message-history .message-grid {
    height: 67px !important;
}
.author {
    margin-top: 18px !important;
    margin-left: 43px !important;
    font-weight: 600 !important;
}
.count-messages {
    float: right;
    margin: -13px;
    margin-right: 2px;
}
.tab-content .show {
    display: block !important;
}
.define-width {
    width: 300px !important;
}
.tab-content-2 {
    width: 100%;
}
.iconss {
    width: 30px;
}